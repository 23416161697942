import { render, staticRenderFns } from "./ConventionVolunteersAssignment.vue?vue&type=template&id=bb81740a&scoped=true&"
import script from "./ConventionVolunteersAssignment.vue?vue&type=script&lang=js&"
export * from "./ConventionVolunteersAssignment.vue?vue&type=script&lang=js&"
import style0 from "./ConventionVolunteersAssignment.vue?vue&type=style&index=0&id=bb81740a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb81740a",
  null
  
)

export default component.exports