<template>

<app-content :loading="is.loading">

	<app-content-body v-if="!is.loading" :is-call="true">

		<app-content-call :loading="is.confirming" :icon="isClashing ? 'clash' : 'volunteers'" :theme="theme" :title="title" :text="text" :has-cancel="true" button="Confirm" v-on:click="onConfirmClick" v-on:cancel="onCancelClick">

			<app-content-alert theme="blue" v-if="!isVolunteering && slot.role.eligibility === $constants.volunteers.role.eligibility.review" class="slot-note">This role requires approval to confirm your assignment. You will receive a notification once reviewed.</app-content-alert>

			<div class="slot">

				<div class="slot-item"><small>Role</small>{{ slot.role.name }}</div>
				<div class="slot-item" v-if="slot.role.type === $constants.volunteers.role.type.schedule"><small>Slot</small>{{ slot.name }}</div>

			</div>

			<com-clashes :clashes="clashes" type="slot" />

		</app-content-call>
	
	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-clashes': () => import('./common/Clashes')
	},

	data: function() {

		return {
			is: {
				loading: true,
				confirming: false
			},
			slot: {},
			clashes: []
		}

	},

	created: function() {

		this.load();

	},

	computed: {

		title: function() {

			if (this.slot.role.type === this.$constants.volunteers.role.type.schedule) {

				return this.isVolunteering ? 'Cancel Slot' :  'Confirm Slot'

			} else {

				return this.isVolunteering ? 'Cancel Role' :  'Confirm Role'

			}

		},

		text: function() {

			if (this.slot.role.type === this.$constants.volunteers.role.type.schedule) {

				return this.isVolunteering ? 'Please confirm you no longer wish to volunteer for this slot.' : 'Please confirm you wish to volunteer for this slot.'

			} else {

				return this.isVolunteering ? 'Please confirm you no longer wish to volunteer for this role.' : 'Please confirm you wish to volunteer for this role.'

			}

		},

		isClashing: function() {

			return this.clashes.length && !this.isVolunteering

		},

		theme: function() {

			return this.isVolunteering || this.isClashing ? 'red' : 'blue'

		},

		isVolunteering: function() {

			return this.slot.session.is.volunteering

		},

		redirect: function() {

			if (this.$route.query.redirect === 'slots') {

				return {
					name: 'Convention.Volunteers.Slots'
				}

			} else if (this.$route.query.redirect === 'assignments') {

				return {
					name: 'Convention.Volunteers.Assignments'
				}

			} else if (this.$route.query.redirect === 'role') {

				return {
					name: 'Convention.Volunteers.Role',
					params: {
						id: this.slot.role.id
					}
				}

			} else {

				return {
					name: 'Convention.Volunteers.Slots'
				}

			}

		}

	},

	methods: {

		onCancelClick: function() {

			this.$router.push(this.redirect)

		},

		onConfirmClick: async function() {

			this.is.confirming = true

			this.$api[this.isVolunteering ? 'delete' : 'post']('convention/volunteers/slot/' + this.$route.params.id + '/assignment').then(function() {

				this.$router.push(this.redirect)

			}.bind(this))

		},

		load: function() {

			this.$api.get('convention/volunteers/slot/' + this.$route.params.id).then(function(response) {

				Vue.set(this, 'slot', response.item)
				Vue.set(this, 'clashes', response.clashes)

				if (this.slot.session.can.join || this.slot.session.can.leave) {

					this.is.loading = false

				} else {

					this.$router.push(this.redirect)

				}

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.slot {
	display: flex;
	margin: 20px 0px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 10px;
	width: 100%;
	justify-content: space-between;
}

.slot-item {
	padding: 10px 20px;
	flex-shrink: 0;
	font-weight: 400;
	font-size: 16px;
}

.slot-item small {
	display: block;
	font-weight: 300;
	font-size: 12px;
	color: #666;
	margin-bottom: 2px;
}

.slot-note {
	margin-top: 20px;
	border-radius: 10px;
}

</style>
